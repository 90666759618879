import type { DocumentNode } from 'graphql';
import memoizeOne from 'memoize-one';

import { getApolloClient } from '@confluence/graphql';

import {
	ContentSubTypeAndStatusFragment,
	ContentSubTypeFragment,
	PTPageSubTypeFragment,
} from './graphql/SubTypeAndStatus.graphql';

export type CachedPageData = {
	subType: string | undefined; // undefined if FG disabled, no contentId, or not found in fragmentData
	isArchived: boolean | undefined; // undefined if FG disabled, no contentId, or not found in fragmentData
	gqlType: string | undefined; // undefined if FG disabled, or no contentId
};

const FRAGMENTS_BY_TYPE: { gqlType: string; fragment: DocumentNode }[] = [
	{
		gqlType: 'Content',
		fragment: ContentSubTypeAndStatusFragment,
	},
	{
		gqlType: 'Content',
		fragment: ContentSubTypeFragment, // If we don't have `status` cached but do have `subType` cached, this ensures we still fast load it. We prefer fast loading live docs even if we're uncertain whether they're archived.
	},
	{
		gqlType: 'PTPage',
		fragment: PTPageSubTypeFragment,
	},
];

/* Retrieves subtype and status by contentId from Apollo cache by looking at types returned by multiple queries.
 * Why each GQL type is used:
 * PTPage is used by: Pages retrieved by Page Tree
 * Content is used by: Current page, pages retrieved by Recents (Worked on/Created by me/etc), and pages retrieved by Home's Discover feed (Following/Popular/etc)
 * NOTE: This function shouldn't need to be invoked unless the tenant has the live pages feature enable. It's recommended to make that check before invoking this function.
 */
export const getCachedPageData: (contentId?: string) => CachedPageData = memoizeOne(
	(contentId?: string) => {
		if (!contentId) {
			return { subType: undefined, isArchived: undefined, gqlType: undefined };
		}

		const client = getApolloClient();

		let subType: string | undefined;
		let isArchived: boolean | undefined = undefined;
		let gqlTypeUsed: string | undefined;
		FRAGMENTS_BY_TYPE.some(({ gqlType, fragment }) => {
			try {
				// readFragment throws an error if it finds the item in the cache without both id and subtype, so we wrap in try/catch
				const fragmentData = client.readFragment({
					id: `${gqlType}:${contentId}`,
					fragment,
				});
				if (fragmentData?.subType) {
					subType = fragmentData.subType;
					isArchived = fragmentData.status
						? fragmentData.status?.toLowerCase?.() === 'archived'
						: undefined;
					gqlTypeUsed = gqlType;
					return true;
				}
			} catch {}
		});
		return { subType, isArchived, gqlType: gqlTypeUsed };
	},
);
